import React from 'react'
import PropTypes from 'prop-types'
import styles from './CTA.module.scss'
import { Link } from 'gatsby'
import TransitionLink from 'gatsby-plugin-transition-link'
import { TYPES } from './constants'
import classnames from 'classnames'

const anchorRegex = /^\/?#[\w-]*/g

const getLocationProperty = _ => {
  return typeof window !== 'undefined' && window.location && window.location[_]
}

const CTA = ({ children, type, className, to: propsTo, active, causeTransition, ...props }) => {
  let Tag = 'button'
  const origin = getLocationProperty('origin')
  const prevPath = getLocationProperty('pathname')
  let pathProps = {}

  if (propsTo) {
    const to = propsTo.replace(origin, '')
    const isAnchorNavigation = to.match(anchorRegex)
    const startsWithSlash = to.match(/^\//g)

    if (!isAnchorNavigation && !startsWithSlash) {
      Tag = 'a'
      pathProps.href = to
    } else {
      Tag = causeTransition ? TransitionLink : Link
      pathProps.to = isAnchorNavigation && !startsWithSlash ? `/${to}` : to
    }
  }

  if (causeTransition) {
    pathProps.exit = {
      length: 1,
    }
    pathProps.entry = {
      delay: 0,
    }
  }

  pathProps.state = {
    prevPath,
  }

  return (
    <Tag
      className={classnames([styles[type], className, styles.cta, { [styles[`${type}-active`]]: active }])}
      {...pathProps}
      {...props}
    >
      <span className={styles.contentContainer}>{children}</span>
      {type === 'tertiary' && <span className={styles.underline}></span>}
    </Tag>
  )
}

CTA.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  type: PropTypes.oneOf(TYPES).isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  causeTransition: PropTypes.bool,
}

export default CTA
