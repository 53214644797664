import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import SEO, { SEOProps } from '@components/SEO'
import FooterContainer from '@containers/FooterContainer'
import HeaderTopNavContainer from '@containers/HeaderTopNavContainer'
import HeaderLeftNav from '@components/HeaderLeftNav'
// import PageContent from '@components/PageContent'

import { LAYOUT_STYLE } from './constants'

import styles from './Layout.module.scss'
const cx = classnames.bind(styles)

const Layout = ({
  children,
  onOpacityChange,
  className,
  linksClassName,
  headerClassName,
  footerClassName,
  headerProps = {},
  seo = {},
  layoutStyle,
  links,
  // heading,
  // containerDivider,
  smoothScroll,
}) => {
  const renderTopNavAndContent = () => (
    <>
      <HeaderTopNavContainer
        className={cx('header', headerClassName)}
        onOpacityChange={onOpacityChange}
        {...headerProps}
      />
      {children}
    </>
  )
  const renderLeftNavAndContent = () => (
    <>
      <HeaderLeftNav className={linksClassName} links={links} />
      {/* <PageContent heading={heading} containerDivider={containerDivider}>
        {children}
      </PageContent> */}
      {children}
    </>
  )

  useEffect(() => {
    if (!window) {
      return
    }

    const html = document.querySelector('html')
    if (smoothScroll) {
      html.style.scrollBehavior = 'smooth'
    } else {
      html.style.scrollBehavior = 'auto'
    }
  }, [smoothScroll])

  return (
    <div className={cx('page', className)}>
      <SEO {...seo} />
      {layoutStyle === LAYOUT_STYLE['leftNav'] ? renderLeftNavAndContent() : renderTopNavAndContent()}
      <FooterContainer className={footerClassName} />
    </div>
  )
}

Layout.defaultProps = {
  layoutStyle: LAYOUT_STYLE['topNav'],
  containerDivider: true,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  linksClassName: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  headerProps: PropTypes.object,
  onOpacityChange: PropTypes.func,
  seo: PropTypes.shape(SEOProps),
  layoutStyle: PropTypes.oneOf(Object.keys(LAYOUT_STYLE)),
  links: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, path: PropTypes.string, active: PropTypes.bool })
  ),
  heading: PropTypes.string,
  containerDivider: PropTypes.bool,
  smoothScroll: PropTypes.bool,
}

export default Layout
