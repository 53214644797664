/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import HeaderTopNav from '@components/HeaderTopNav'

const HeaderTopNavContainer = props => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulNavigation {
        nodes {
          mainLinks {
            label
            url
          }
          sideLink {
            label
            url
          }
        }
      }
    }
  `)

  const navigationNodes = data.allContentfulNavigation.nodes
  const hasNavigationNodes = navigationNodes && navigationNodes[0]
  const navigation = hasNavigationNodes && navigationNodes[0]

  return (
    <HeaderTopNav
      mainLinks={navigation && navigation.mainLinks ? navigation.mainLinks : []}
      rightLink={navigation && navigation.sideLink ? navigation.sideLink : {}}
      {...props}
    />
  )
}

export default HeaderTopNavContainer
