import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import setupRemovePolyfill from '@utils/polyfills/remove'

const PORTAL_ID = 'modal-portal'

const Modal = ({ children }) => {
  const [element, setElement] = useState()

  useEffect(() => {
    // For IE 11
    setupRemovePolyfill()

    const el = document.createElement('div')
    el.setAttribute('id', PORTAL_ID)
    document.getElementsByTagName('body')[0].appendChild(el)
    setElement(el)

    return () => {
      document.getElementById(PORTAL_ID).remove()
    }
  }, [])

  if (!element) {
    return null
  }

  return createPortal(children, element)
}

export default Modal
