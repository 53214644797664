import React from 'react'
import PropTypes from 'prop-types'
import Social from '@components/Social'
import Text from '@components/Text'
import { Link } from 'gatsby'

import styles from './Footer.module.scss'
import classname from 'classnames/bind'
const cx = classname.bind(styles)

const Footer = ({ socialLinks, text, legalLink, className }) => (
  <div className={cx('container', 'grid', 'tablet-grid', 'mobile-grid', className)}>
    <Social
      className={cx('col-l', 'col-l-o-2', 'col-l-3', 'col-m', 'col-m-3', 'col-s', 'col-s-10', 'social-links')}
      links={socialLinks}
    />
    <div className={cx('text-container', 'col-l', 'col-m', 'col-s', 'col-s-10')}>
      <Text className={cx('text')} type="fineprint1" tag="p">
        {text}
      </Text>
      <Link className={cx('link')} to={legalLink.url}>
        {legalLink.label}
      </Link>
      <p>
        &nbsp;|{' '}
        <a
          href="//assets.ctfassets.net/mw8dq0fsj7wk/52CXwNrHetUfHQWEMnITkU/3a4543c75f6a17212683a6fa0e6c7542/caprivacypolicy.pdf"
          className={cx('link')}
          target="_blank"
          rel="noreferrer"
        >
          California Employee Privcy Notice
        </a>
      </p>
    </div>
  </div>
)

Footer.propTypes = {
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        description: PropTypes.string,
        url: PropTypes.string.isRequired,
      }),
      url: PropTypes.string,
    })
  ),
  text: PropTypes.string,
  legalLink: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
  className: PropTypes.string,
}

export default Footer
