import React, { useRef, useEffect, useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useTransition, useChain, animated } from 'react-spring'
import { Link } from 'gatsby'

import classnames from 'classnames/bind'
import styles from './MobileMenu.module.scss'
import Logo from '@components/Logo'
import CTA from '@components/CTA'
import CloseIcon from './CloseIcon'
import Modal from '@components/Modal'

import variables from '@styles/variables.module.scss'
const cx = classnames.bind(styles)

const mobileMenuAnimationDuration = Number(variables.mobileMenuAnimationDuration.replace('ms', '')) / 1000

const AnimatedCTA = animated(CTA)

const opacityTransition = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
}

const MobileMenu = forwardRef(({ links, isActive, handleClose, handleAnimationFinish = () => {} }, ref) => {
  const [showContainer, setShowContainer] = useState(isActive)
  const [blurAnimationTimeout, setBlurTimeout] = useState()
  const circleTransitionRef = useRef()
  const headerTransitionRef = useRef()
  const linksTransitionRef = useRef()
  const overflowTransitionRef = useRef()

  const overflowTransition = useTransition(isActive, null, {
    ...opacityTransition,
    ref: overflowTransitionRef,
  })

  const circleTransition = useTransition(isActive, null, {
    from: {
      top: '3.6rem',
      right: '10%',
      transform: 'scale(0)',
    },
    enter: {
      transform: 'scale(1)',
      top: '-15rem',
      right: '-33%',
    },
    leave: {
      top: '3.6rem',
      right: '10%',
      transform: 'scale(0)',
    },
    ref: circleTransitionRef,
    onRest: flag => {
      if (!isActive && showContainer && flag) {
        handleAnimationFinish()
        setShowContainer(false)
      }
    },
  })

  const headerTransition = useTransition(isActive, null, {
    ...opacityTransition,
    ref: headerTransitionRef,
  })

  const linkTransition = useTransition(isActive ? links : [], item => item.url, {
    ref: linksTransitionRef,
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(20px)' },
    trail: 300 / links.length,
    unique: true,
  })

  // Chain transitions so they can happen one after another
  useChain(
    isActive
      ? [
          { current: overflowTransitionRef.current },
          { current: circleTransitionRef.current },
          { current: headerTransitionRef.current },
          { current: linksTransitionRef.current },
        ]
      : [
          { current: linksTransitionRef.current },
          { current: headerTransitionRef.current },
          { current: circleTransitionRef.current },
          { current: overflowTransitionRef.current },
        ],
    isActive
      ? [
          mobileMenuAnimationDuration,
          mobileMenuAnimationDuration,
          mobileMenuAnimationDuration + 0.35,
          mobileMenuAnimationDuration + 0.35,
        ]
      : [0, 0, 0.35, 0.35],
    1000
  )

  useEffect(() => {
    // Controls the container position without affecting the animations
    if (!showContainer && isActive) {
      setShowContainer(isActive)
    }

    if (blurAnimationTimeout) {
      clearTimeout(blurAnimationTimeout)
    }

    const gatsbyContainer = document.getElementById('___gatsby')
    if (!gatsbyContainer) {
      return
    }

    if (isActive) {
      gatsbyContainer.style.filter = 'blur(2px)'
    } else {
      setBlurTimeout(
        setTimeout(() => {
          gatsbyContainer.style.filter = null
        }, (mobileMenuAnimationDuration + 0.35) * 1000)
      )
    }
  }, [isActive, showContainer])

  useEffect(() => {
    const gatsbyContainer = document.getElementById('___gatsby')
    if (!gatsbyContainer) {
      return
    }

    gatsbyContainer.style.transition = 'filter 300ms ease'
  }, [])

  const handleContentClick = e => e.stopPropagation()

  return (
    <Modal>
      <div ref={ref} className={cx('container', { 'container--active': showContainer })} onClick={handleClose}>
        {overflowTransition.map(
          ({ item, props, key }) =>
            item && <animated.div key={key} style={props} className={cx('overflow')}></animated.div>
        )}
        <div className={cx('content')} onClick={handleContentClick}>
          {circleTransition.map(
            ({ item, props, key }) =>
              item && <animated.div key={key} style={props} className={cx('circle')}></animated.div>
          )}
          {headerTransition.map(
            ({ item, props, key }) =>
              item && (
                <animated.div key={key} className={cx('header')} style={props}>
                  <Link to="/" onClick={handleClose}>
                    <Logo firstFill="#FFF"></Logo>
                  </Link>
                  <CloseIcon onClick={handleClose} />
                </animated.div>
              )
          )}
          <div className={cx('links')}>
            {linkTransition.map(({ item, props, key }) => (
              <AnimatedCTA
                onClick={handleClose}
                key={key}
                to={item.url}
                className={cx('link')}
                style={props}
                type="quinary"
              >
                {item.label}
              </AnimatedCTA>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
})

MobileMenu.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string, label: PropTypes.string })),
  isActive: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAnimationFinish: PropTypes.func,
}

export default MobileMenu
