export const parseImage = image =>
  image.file && {
    url: image.file.url,
    description: image.description,
  }

export const parseDescription = description => description.internal && description.internal.content

export const parseAnchor = (url = '') => {
  let anchor = url
  if (typeof window !== `undefined` && location && location.origin) {
    anchor = anchor.replace(`${location.origin}${location.pathname}`, '')
  }
  return anchor.replace('#', '')
}
