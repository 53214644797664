import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import BaseSvg from '@components/BaseSvg'
import VisuallyHidden from '@components/VisuallyHidden'
import variables from '@styles/variables.module.scss'

const Group1001Logo = ({ style, firstFill = variables.charcoal, secondFill = variables.copper }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <>
      <VisuallyHidden>{site.siteMetadata.title}</VisuallyHidden>
      <span role="presentation">
        <BaseSvg style={style} width="131" height="18" viewBox="0 0 115 16">
          <g fillRule="nonzero" fill="none">
            <path
              d="M12.553 2.068l-2.36 2.386C9.499 3.688 8.516 3.2 7.228 3.2c-2.22 0-3.817 1.713-3.817 4.199 0 2.741 1.766 4.28 3.828 4.28 1.972 0 3.123-.895 3.512-2.578H7.369V6.47h6.663c.05.377.068.724.068 1.069 0 4.565-3.173 7.247-6.742 7.247C3.224 14.787 0 11.853 0 7.399 0 3.067 3.065 0 7.387 0c2.202 0 3.957.773 5.166 2.068M54.614 8.775c0 3.903-2.303 6.023-6.081 6.023-3.778 0-6.07-2.12-6.07-6.023V.266h3.321v8.376c0 1.957 1.09 2.886 2.749 2.886 1.666 0 2.756-.929 2.756-2.886V.266h3.32v8.51h.005zM62.28 6.548h-2.586V3.226h2.587c1.19 0 1.925.58 1.925 1.672 0 1.08-.734 1.65-1.925 1.65m.05-6.407h-5.95v14.268h3.31V9.59h2.665c3.4 0 5.217-1.876 5.217-4.687.004-2.867-1.81-4.761-5.242-4.761M34.206 11.505c-2.206 0-3.994-1.838-3.994-4.102 0-2.268 1.788-4.107 3.994-4.107 2.205 0 3.994 1.839 3.994 4.107 0 2.264-1.789 4.102-3.994 4.102M34.199.022c-3.965 0-7.171 3.3-7.171 7.392 0 4.05 3.206 7.37 7.17 7.37 4.005 0 7.193-3.32 7.193-7.37C41.387 3.318 38.2.022 34.199.022M21.04 6.552h-2.59V3.226h2.59c1.192 0 1.926.58 1.926 1.672 0 1.084-.734 1.654-1.925 1.654m5.095-1.565c0-2.864-1.814-4.795-5.246-4.795h-5.48v14.236h3.037v-4.68h.824l3.018 4.68h3.847l-3.447-4.987c2.26-.547 3.447-2.175 3.447-4.454"
              fill={firstFill}
            />
            <path
              d="M108.471.163v14.235h-3.302V3.614h-1.281V.163h4.583zm-34.504 0v14.235h-3.303V3.614h-1.28V.163h4.583zm22.725-.03c3.835 0 6.886 3.178 6.886 7.118 0 3.903-3.05 7.1-6.886 7.1-3.793 0-6.865-3.2-6.865-7.1 0-3.94 3.069-7.118 6.865-7.118zm-14.64 0c3.857 0 6.926 3.178 6.926 7.118 0 3.903-3.07 7.1-6.926 7.1-3.818 0-6.905-3.2-6.905-7.1 0-3.94 3.087-7.118 6.905-7.118zm14.65 3.16c-2.126 0-3.846 1.768-3.846 3.95 0 2.183 1.724 3.952 3.846 3.952 2.123 0 3.847-1.772 3.847-3.951 0-2.183-1.724-3.951-3.847-3.951zm-14.64 0c-2.137 0-3.867 1.768-3.867 3.95 0 2.183 1.73 3.952 3.867 3.952 2.138 0 3.868-1.772 3.868-3.951 0-2.183-1.734-3.951-3.868-3.951z"
              fill={secondFill}
            />
          </g>
        </BaseSvg>
      </span>
    </>
  )
}

Group1001Logo.propTypes = {
  firstFill: PropTypes.string,
  secondFill: PropTypes.string,
  style: PropTypes.object,
}

export default Group1001Logo
