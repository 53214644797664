import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import styles from './Social.module.scss'

const cx = classnames.bind(styles)

const Social = ({ className, links }) => (
  <ul className={cx('social-links', className)}>
    {links &&
      links.map(item => (
        <li key={item.url} className={cx('social-link')}>
          <a className={cx('link')} href={item.url} target="_blank" rel="noopener noreferrer">
            <img src={item.image.url} alt={item.image.description} />
          </a>
        </li>
      ))}
  </ul>
)

Social.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
      }),
    })
  ),
}

export default Social
