import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import style from './HeaderTopNav.module.scss'

import Logo from '@components/Logo'
import CTA from '@components/CTA'
import { useBreakpoint } from '@utils/hooks'
import scrollingelement from '@utils/polyfills/scrollingelement'
import { headerPropTypes } from './constants'
import MenuButton from './MenuButton'
import MobileMenu from './MobileMenu'

const ANIMATION_SCROLL_MAX = 40
const MIN_SCALE = 0.9

const cx = classNames.bind(style)

const HeaderTopNav = ({ mainLinks, rightLink, className, onOpacityChange = () => {} }) => {
  /* Controls the mobile menu */
  const [showMobileMenu, setMobileMenu] = useState(false)
  /* Controls the header for mobile animation */
  const [showHeader, setShowHeader] = useState(true)
  /* Controls the mobile button animation  */
  const [isMobileButtonActive, triggerMobileButtonAnimation] = useState(false)
  /* Save scrollTop to check scroll direction */
  const [lastScrollTop, setScrollTop] = useState(0)

  const [opacity, setOpacity] = useState(1)
  const [scale, setScale] = useState(1)

  const menuRef = useRef()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint === 'small'
  const isTablet = breakpoint === 'medium'
  const isDesktop = breakpoint === 'large'
  const handleMenuButtonClick = newState => () => setMobileMenu(state => (newState !== undefined ? newState : !state))

  const handleCloseAnimationFinish = () => {
    //Manually trigger the mobile menu button animation after the closing animation from the menu is over
    triggerMobileButtonAnimation(true)
  }

  useEffect(() => {
    scrollingelement()
  }, [])

  const handleScroll = () => {
    if (document.scrollingElement.scrollTop < ANIMATION_SCROLL_MAX) {
      if (isTablet || isDesktop) {
        setOpacity(1 - document.scrollingElement.scrollTop / ANIMATION_SCROLL_MAX)
      }
      if (isDesktop) {
        setScale(Math.max(1 - document.scrollingElement.scrollTop / (ANIMATION_SCROLL_MAX * 2), MIN_SCALE))
      }
    } else {
      if (isMobile) {
        // Brings back header on mobile scroll directing to top
        const currentScroll = window.pageYOffset || document.documentElement.scrollTop
        if (currentScroll > lastScrollTop) {
          setShowHeader(false)
        } else {
          setShowHeader(true)
          setOpacity(1)
        }
        setScrollTop(currentScroll)
      } else {
        // Default opacity to 0 after scroll point.
        if (opacity !== 0) {
          setOpacity(0)
        }

        // Default scale to minimum after scroll point.
        if (isDesktop && scale !== MIN_SCALE) {
          setScale(MIN_SCALE)
        }
      }
    }
  }

  useEffect(() => {
    // Watch the mobile menu state to disable scroll and trigger button animation manually
    if (showMobileMenu) {
      triggerMobileButtonAnimation(false)
      disableBodyScroll(menuRef.current)
    } else {
      enableBodyScroll(menuRef.current)
    }
  }, [showMobileMenu])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollTop, breakpoint])

  const containerStyle = isMobile
    ? {
        transform: showHeader || window.pageYOffset <= 0 ? 'translateY(0)' : 'translateY(-100%)',
      }
    : {}

  const headerDisabled = opacity !== 1

  const opacityStyle = {
    transition: 'opacity 300ms ease',
    opacity,
    pointerEvents: headerDisabled && 'none',
  }

  const logoStyle = isDesktop
    ? {
        transition: 'transform 300ms ease',
        opacity,
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        pointerEvents: 'none',
      }
    : opacityStyle

  useEffect(() => {
    onOpacityChange(opacityStyle)
  }, [opacity])

  return (
    <>
      <div className={cx('header', className)} style={containerStyle}>
        <div className={cx('header-inner', 'grid', 'tablet-grid')}>
          <div
            className={cx('col-l', 'col-l-2', 'col-m', 'col-m-2', 'logo-container')}
            style={breakpoint === 'medium' ? opacityStyle : {}}
          >
            <Link className={cx(headerDisabled ? 'no-pointer-events' : 'logo')} to="/">
              <Logo style={logoStyle} />
            </Link>
          </div>
          {mainLinks && (
            <div style={opacityStyle} className={cx('nav-links', 'col-l', 'col-m')}>
              {mainLinks &&
                mainLinks.map(item => (
                  <CTA key={item.label} to={item.url} className={cx('link')} type="quaternary">
                    {item.label}
                  </CTA>
                ))}
              <div className={cx('right-link')}>
                {rightLink && rightLink.label && (
                  <CTA style={opacityStyle} to={rightLink.url} type="quaternary">
                    {rightLink.label}
                  </CTA>
                )}
              </div>
            </div>
          )}
          <MenuButton
            style={logoStyle}
            className={cx('mobile-menu-button')}
            isActive={showMobileMenu}
            onClick={handleMenuButtonClick(true)}
            triggerAnimation={isMobileButtonActive}
          />
        </div>
      </div>
      <MobileMenu
        ref={menuRef}
        handleAnimationFinish={handleCloseAnimationFinish}
        handleClose={handleMenuButtonClick(false)}
        isActive={showMobileMenu}
        links={[...(mainLinks || []), rightLink]}
      />
    </>
  )
}

HeaderTopNav.defaultProps = {
  toggleHeader: () => {},
}

HeaderTopNav.propTypes = {
  ...headerPropTypes,
  className: PropTypes.string,
  onOpacityChange: PropTypes.func,
}

export default HeaderTopNav
