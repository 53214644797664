import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import InternalHeader from '@components/InternalHeader'

import style from './HeaderLeftNav.module.scss'

const cx = classNames.bind(style)

const HeaderTopNav = ({ links, className }) => {
  return (
    <div className={cx('menu-container')}>
      <div className={cx('header-inner')}>
        <InternalHeader links={links} linksClassName={className} className={cx('items-holder')} />
      </div>
    </div>
  )
}

HeaderTopNav.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, path: PropTypes.string, active: PropTypes.bool })
  ),
}

export default HeaderTopNav
