import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Footer from '@components/Footer'

import { parseImage } from '@utils/contentful'

const FooterContainer = props => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFooter {
        nodes {
          socialLinks {
            url
            image {
              file {
                url
              }
              description
            }
          }
          text
          legalLink {
            label
            url
          }
        }
      }
    }
  `)

  const footerNodes = data.allContentfulFooter.nodes
  const hasFooterNodes = footerNodes && footerNodes[0]

  const footer =
    hasFooterNodes && footerNodes[0]
      ? {
          ...footerNodes[0],
          socialLinks: footerNodes[0].socialLinks.map(item => ({
            url: item.url,
            image: parseImage(item.image),
          })),
        }
      : null

  return (
    footer && <Footer legalLink={footer.legalLink} socialLinks={footer.socialLinks} text={footer.text} {...props} />
  )
}

export default FooterContainer
