import React from 'react'
import BaseSVG from '@components/BaseSvg'

const CloseIcon = props => (
  <BaseSVG
    {...props}
    style={{
      transform: 'rotate(45deg)',
    }}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#FFF" fillRule="nonzero">
      <path d="M11.99.37v20H9.99v-20z" />
      <path d="M.99 9.368h20v2.002h-20z" />
    </g>
  </BaseSVG>
)

export default CloseIcon
