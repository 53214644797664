import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames/bind'

import styles from './InternalHeader.module.scss'
import { Link } from 'gatsby'
import Logo from '@components/Logo'
import CTA from '@components/CTA'

const cx = classnames.bind(styles)

const InternalHeader = ({ links, className, linksClassName }) => (
  <div className={cx('container', className)}>
    <Link className={cx('logo')} to="/">
      <Logo />
    </Link>
    <div className={cx('nav-links', linksClassName)}>
      {links.map(
        item =>
          item.label &&
          item.path && (
            <CTA
              key={item.label}
              to={item.path}
              className={cx('link')}
              active={item.active}
              causeTransition={true}
              type="quaternary"
            >
              {item.label}
            </CTA>
          )
      )}
    </div>
  </div>
)

InternalHeader.propTypes = {
  className: PropTypes.string,
  linksClassName: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
}

export default InternalHeader
