/* eslint-disable */
const setupRemovePolyfill = () => {
  const arr = [Element.prototype, CharacterData.prototype, DocumentType.prototype]
  arr.forEach(function(item) {
    if (item.hasOwnProperty('remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return
        }
        this.parentNode.removeChild(this)
      },
    })
  })
}

export default setupRemovePolyfill
