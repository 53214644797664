import React, { useState, useEffect } from 'react'
import BaseSVG from '@components/BaseSvg'
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'
import styles from './MenuButton.module.scss'

const cx = classNames.bind(styles)

const MenuButton = ({ width = '18', height = '18', isActive, triggerAnimation, ...props }) => {
  const [active, setAnimation] = useState(isActive)

  useEffect(() => {
    if (isActive) {
      setAnimation(true)
    }

    if (!isActive && triggerAnimation) {
      setAnimation(false)
    }
  }, [isActive, triggerAnimation])

  return (
    <BaseSVG width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#000" fillRule="evenodd">
        <circle className={cx('circle', { c1: active })} cx="3" cy="3" r="3" />
        <circle className={cx('circle', { c2: active })} cx="3" cy="15" r="3" />
        <circle className={cx('circle', { c3: active })} cx="15" cy="3" r="3" />
        <circle className={cx('circle', { c4: active })} cx="15" cy="15" r="3" />
      </g>
    </BaseSVG>
  )
}

MenuButton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  triggerAnimation: PropTypes.bool,
  isActive: PropTypes.bool,
}

export default MenuButton
