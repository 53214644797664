import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import style from './VisuallyHidden.module.scss'

export default function VisuallyHidden(props) {
  return <span className={classnames(style['visuallyHidden'])}>{props.children}</span>
}

VisuallyHidden.propTypes = {
  children: PropTypes.node.isRequired,
}
