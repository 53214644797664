import PropTypes from 'prop-types'

export const headerLinkPropType = PropTypes.shape({
  label: PropTypes.string,
  url: PropTypes.string,
})

export const headerPropTypes = {
  mainLinks: PropTypes.arrayOf(headerLinkPropType),
  rightLink: headerLinkPropType,
}
