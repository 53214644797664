/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

// generics
import favicon16 from '@src/images/favicon16.png'
import favicon32 from '@src/images/favicon32.png'
import favicon57 from '@src/images/favicon57.png'
import favicon76 from '@src/images/favicon76.png'
import favicon96 from '@src/images/favicon96.png'
import favicon128 from '@src/images/favicon128.png'
import favicon192 from '@src/images/favicon192.png'
import favicon228 from '@src/images/favicon228.png'

// android and ios
import favicon196 from '@src/images/android/favicon196.png'
import favicon120 from '@src/images/ios/favicon120.png'
import favicon152 from '@src/images/ios/favicon152.png'
import favicon180 from '@src/images/ios/favicon180.png'
import safariPinned from '@src/images/ios/safari-pinned-tab.svg'

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        // generics
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${favicon16}` },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
        { rel: 'icon', type: 'image/png', sizes: '57x57', href: `${favicon57}` },
        { rel: 'icon', type: 'image/png', sizes: '76x76', href: `${favicon76}` },
        { rel: 'icon', type: 'image/png', sizes: '96x96', href: `${favicon96}` },
        { rel: 'icon', type: 'image/png', sizes: '128x128', href: `${favicon128}` },
        { rel: 'icon', type: 'image/png', sizes: '192x192', href: `${favicon192}` },
        { rel: 'icon', type: 'image/png', sizes: '228x228', href: `${favicon228}` },
        { rel: 'icon', type: 'image/png', sizes: '228x228', href: `${favicon228}` },

        // Android
        { rel: 'shortcut icon', type: 'image/png', sizes: '196x196', href: `${favicon196}` },

        // iOS
        { rel: 'apple-touch-icon', type: 'image/png', sizes: '120x120', href: `${favicon120}` },
        { rel: 'apple-touch-icon', type: 'image/png', sizes: '152x152', href: `${favicon152}` },
        { rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: `${favicon180}` },
        { rel: 'mask-icon', type: 'text/svg', color: '#5bbad5', href: `${safariPinned}` },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `msapplication-TileColor`,
          content: `#da532c`,
        },
        {
          name: `msapplication-config`,
          content: `/images/ms/browserconfig.xml`,
        },
        {
          name: `theme-color`,
          content: `#ffffff`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export const SEOProps = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

SEO.propTypes = SEOProps

export default SEO
